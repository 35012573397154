<template>
  <div>
    <ca-header heading="Verlängerungen" />
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="order-extend-list"
        :base-query="baseQuery"
        @row-clicked="(item) => $router.push({name: 'OrderDetail', params: {orderId: item._id}})"
      >
        <template
          slot="status"
          slot-scope="{value: status, unformatted: statusCode}"
        >
          <span
            class="badge"
            :class="getStatusClass(statusCode)"
          >{{ status }}</span>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import formatDate from '../../filters/formatDate.js'
export default {
  name: 'OrderExtendList',
  components: {
    DataTable,
    CaHeader
  },
  computed: {
    fields () {
      const fields = [
        {
          label: 'Garantie/Anfrage',
          key: 'hnWarrantynumber'
        },
        {
          label: 'Erstellt am',
          key: 'createdAt',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          filter: {
            type: 'DATERANGE'
          }
        },
        {
          label: 'UN',
          key: 'product.rate.product.name'
        },
        {
          label: 'Produkt',
          key: 'product.rate.name'
        },
        {
          label: 'Halter',
          key: 'owner',
          formatter: (owner) => `${owner.firstname} ${owner.lastname}`
        },
        {
          label: 'Kennzeichen',
          key: 'car.licensePlate'
        },
        {
          label: 'Vertragsende',
          key: 'product.endContractDate',
          formatter: value => formatDate(value, 'DD.MM.YYYY'),
          filter: {
            type: 'DATERANGE'
          }
        }
      ]
      return fields
    },
    baseQuery () {
      const baseQuery = {
        $sort: { 'product.endContractDate': 1 }
      }
      if (this.$route.meta.isOverview) {
        let userId
        const userIdFromParams = this.$route.params.userId
        if (userIdFromParams === 'me') {
          userId = this.$store.getters['auth/user'].user._id
        } else {
          userId = userIdFromParams
        }

        baseQuery.userId = userId
      } else {
        baseQuery.$client = {
          $showAll: true
        }
      }
      return baseQuery
    },
    isOverview () {
      return this.$route.meta.isOverview
    }
  }
}
</script>

<style scoped lang="scss">

</style>
